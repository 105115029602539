import React, { useState, useEffect, useRef } from 'react';
import "./chat.css"

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const inputRef = useRef(null); // Create a ref for the input

  const handleSendMessage = () => {
    if (input.trim()) {
      setMessages([...messages, `$- ${input}`]); // Add $- as prefix to messages
      setInput('');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage(); // Call the function on Enter key
    }
  };

  useEffect(() => {
    inputRef.current.focus(); // Keep the input focused on mount
  }, []);

  return (
      <div className="flex flex-col items-center justify-center h-screen bg-black text-green-500 font-mono">
        <div className="w-full max-w-md p-5 border-2 border-green-500 bg-black">
          <h2 className="text-center text-green-500">CMD Chat Room</h2>
          <div className="h-64 overflow-y-auto border border-green-500 bg-black p-3 my-5 custom-scrollbar">
            {messages.map((msg, index) => (
                <div key={index} className="typewriter-text mb-2">
                  {msg}
                </div>
            ))}
          </div>
          <div className="flex items-center">
            <span className="text-green-500 mr-2">$-</span>
            <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={handleKeyDown}
                ref={inputRef}
                placeholder="Type a message"
                className="w-full p-2 bg-black text-green-500 border border-green-500 focus:outline-none focus:ring focus:border-green-300"
            />
            <button
                onClick={handleSendMessage}
                className="ml-2 p-2 border border-green-500 bg-black text-green-500 hover:bg-green-700 hover:text-black transition-colors"
            >
              Send
            </button>
          </div>
        </div>
      </div>
  );
};

export default Chat;
