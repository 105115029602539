import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Chat from './components/chat.js';
import Gallery from './components/Gallery.js';
import Chispitalaburra from './components/Chispitalaburra';
import Home from './components/Home.js';
import UnderDevelopment from './components/UnderDevelopment.js';



const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/Chat" element={<Chat />} />
                <Route path="/Gallery" element={<Gallery />} />
                <Route path="/chicle" element={<Chispitalaburra />} />
                <Route path="/h0me" element={<Home />} />
                <Route path='/dev' element={<UnderDevelopment />} />
            </Routes>
        </Router>
    );
};

export default App;
