import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Hearts } from 'react-loader-spinner';
import LoadingSpinner from './LoadingSpinner';
import "./Gallery.css"


const Gallery = () => {
    const [records, setRecords] = useState([]);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false); 
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post('https://lovecompany.xyz/sudodaemon/gal_reg/filter', {});
                console.log(response.data); 
                setRecords(response.data); 
            } catch (error) {
                console.error('Error al obtener los datos de la galería:', error);
            }
            finally {
                setLoading(false);
            }
        };

        fetchData(); 
    }, []);
    if (loading) {
        return <LoadingSpinner />;
    }

    const openModal = (record) => {
        setSelectedRecord(record);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedRecord(null);
    };

    return (
        <div className="gnr_gall_bak">
            <div className="min-h-screen">
                {/* Header */}
                <header className="text-center p-5 bg-gradient-to-r from-red-800 to-black-800 shadow-lg">
                    <h1 className="text-4xl font-bold text-white">Gallery</h1>
                    <p className="mt-2 text-lg text-gray-200">2a6o1.- [nov 6, 2024~ 21:06]</p>
                </header>

                {/* Banner */}

                <div className="text-center p-5">
    <div className="w-full h-[625px] overflow-hidden relative">
        <img
            src={require('../imgs/mary.gif')}
            alt="Banner"
            className="w-full h-full object-cover object-top mt-[-5px]"
        />
    </div>
</div>



                <div className="grid grid-cols-3 gap-4 p-5">
                    {records.map((record) => (
                        <div key={record.id} className="m-2 border border-red-800 shadow-lg rounded-lg" onClick={() => openModal(record)}> 
                            <img
                                src={record.Ardb_base64_img}
                                alt={record.Ardb_nombre}
                                className="w-full h-40 object-cover cursor-pointer rounded-t-lg" 
                            />
                            <div className="p-2 text-white">
                                <h3 className="text-lg font-bold">{record.Ardb_nombre}</h3>
                                <p><strong>Date:</strong> {new Date(record.Ardb_fecha).toLocaleDateString()}</p>
                            </div>
                        </div>
                    ))}
                </div>

                {isModalOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
                        <div className="bg-gray-800 p-5 rounded-lg shadow-lg w-11/12 max-w-4xl relative"> 
                            <button
                                className="absolute top-3 right-3 bg-red-800 text-white px-3 py-1 rounded hover:bg-red-900" 
                                onClick={closeModal}
                            >
                                X
                            </button>
                            <div className="flex flex-col md:flex-row"> 
                                {/* Columna de Imagen */}
                                <div className="flex-shrink-0 mb-4 md:mb-0 md:mr-4"> 
                                    <img
                                        src={selectedRecord.Ardb_base64_img}
                                        alt={selectedRecord.Ardb_nombre}
                                        className="w-full h-auto max-w-xs object-cover" 
                                    />
                                </div>
                                <div className="flex-grow">
                                    <h2 className="text-5xl font-bold text-white">{selectedRecord.Ardb_nombre}</h2>
                                    <p className="text-3xl text-white">{selectedRecord.Ardb_desc}</p>
                                    <p className="text-2xl text-white"><strong>Date:</strong> {new Date(selectedRecord.Ardb_fecha).toLocaleDateString()}</p>
                                    <p className="text-2xl text-white"><strong>Place:</strong> {selectedRecord.Ardb_lugar}</p>
                                    <p className="text-2xl text-white"><strong>Available?:</strong> {selectedRecord.Ardb_stat_disp}</p>
                                    <p className="text-2xl text-white"><strong>Collection Name:</strong> {selectedRecord.Ardb_collection}</p>
                                    <p className="text-2xl text-white"><strong>Collection #:</strong> {selectedRecord.Ardb_num_ins_coll}</p>
                                    <p className="text-2xl text-white"><strong>Nom-Name:</strong> {selectedRecord.Ardb_nom_name}</p>
                                    <a href={selectedRecord.Ardb_yt_url} target="_blank" rel="noopener noreferrer" className="text-red-300">Extra data?</a>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Gallery;
