import React, { useState, useEffect } from 'react';

const UnderDevelopment = () => {
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(false); 

 
  useEffect(() => {
    const timer = setTimeout(() => {
  
      setLoading(false);
      setError(true); 
    }, 3000); 

    return () => clearTimeout(timer); 
  }, []);

  return (
    <div className="min-h-screen flex justify-center items-center bg-black">
      <div className="bg-gray-800 text-white rounded-lg p-8 w-11/12 md:w-2/3 lg:w-1/2">
        {/* Banner Heading */}
        <h2 className="text-3xl font-bold text-center mb-6">Under Development</h2>
        <div className="text-center">
          {loading && (
            <div>
              <div className="w-16 h-16 border-4 border-purple-600 border-t-transparent rounded-full animate-spin mx-auto"></div>
              <p className="mt-4 text-lg">Loading...</p>
            </div>
          )}

          {error && (
            <div>
              {/* Error: Icono de fallo */}
              <div className="flex justify-center mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-12 w-12 text-red-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
              <p className="text-lg text-red-500">We are working! ok?!... love... :*</p>
            </div>
          )}
        </div>

        {/* Footer Message */}
        <div className="text-center mt-6 text-gray-400">
          <p>We are working hard to bring you the best experience. Please check back later.</p>
        </div>
      </div>
    </div>
  );
};

export default UnderDevelopment;
