import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-black py-8">
            {/* Header Section */}
            <header className="text-center mb-8">
                <h1 className="text-4xl font-bold text-white">Welcome</h1>
                <p className="mt-2 text-lg text-gray-300">The L0ve C0mp4ny to serv you</p>
            </header>

            {/* Main Section */}
            <main className="flex flex-col items-center">
                <div className="bg-gray-800 p-6 rounded-lg shadow-xl w-11/12 md:w-2/3 lg:w-1/2">
                    <h2 className="text-2xl font-semibold text-white mb-4">Main Index</h2>
                    <p className="text-gray-400 mb-6">A lot of this is stuff is under development, we work so hard to spread this, stick arround.</p>

                    {/* Buttons Section */}
                    <div className="flex flex-wrap gap-4 justify-center">
                        <Link to="/Chat">
                            <button className="bg-purple-800 text-white py-2 px-6 rounded-md hover:bg-purple-600 focus:outline-none shadow-lg">
                                Chat [nw]
                            </button>
                        </Link>

                        <Link to="/dev">
                            <button className="bg-purple-800 text-white py-2 px-6 rounded-md hover:bg-purple-600 focus:outline-none shadow-lg">
                                Software
                            </button>
                        </Link>

                        <a
                            href="http://13.56.224.166:8080/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <button className="bg-purple-800 text-white py-2 px-6 rounded-md hover:bg-purple-600 focus:outline-none shadow-lg">
                                ######
                            </button>
                        </a>


                        <Link to="/Gallery">
                            <button className="bg-purple-800 text-white py-2 px-6 rounded-md hover:bg-purple-600 focus:outline-none shadow-lg">
                                Gallery
                            </button>
                        </Link>

                        <Link to="/dev">
                            <button className="bg-purple-800 text-white py-2 px-6 rounded-md hover:bg-purple-600 focus:outline-none shadow-lg">
                                Info
                            </button>
                        </Link>

                        <Link to="/dev">
                            <button className="bg-purple-800 text-white py-2 px-6 rounded-md hover:bg-purple-600 focus:outline-none shadow-lg">
                                MineServer
                            </button>
                        </Link>

                    </div>
                </div>
            </main>

            {/* Footer Section */}
            <footer className="mt-8 text-center text-gray-400">
                <p>&copy; 2024 L0ve C0mp4ny. 2a6o1.</p>
            </footer>
        </div>
    );
};

export default Home;
